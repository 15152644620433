import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Slider from "react-slick"
import "lazysizes"
import { Link } from "@reach/router"

const HomePage = () => {
  var settings = {
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
  }
  return (
    <Layout cssClass="home">
      <SEO title="Home Jonny Acevedo" />
      <div className="md:min-h-screen mt-12 md:-mt-12">
        <div className="home-heading md:flex md:min-h-screen">
          <div className="text-center md:text-left md:w-4/5 md:flex flex-col justify-center">
            <span className="text-teal-800">Hi, I'm </span>
            <h1 className="md:text-4xl lg:text-6xl m-0 text-white">
              <span className="text-teal-800">Jonny</span> Acevedo
            </h1>
            <h2 className="md:text-3xl lg:text-4xl my-2 text-gray-200">
              Front-end Developer
            </h2>

            <p className="text-gray-300">
              (I'm not an avocado, I just like them)
            </p>

            <a className="home__btn btn btn--full mt-4 md:mt-16" href="#about">
              About Me
            </a>
          </div>
          <div className="text-center my-12 md:my-0 md:flex flex-col justify-center">
            <img
              loading="lazy"
              className="w-40 md:w-3/5 m-0 md:my-4 inline-block self-end"
              src="https://res.cloudinary.com/dl8ynwaiu/image/upload/v1587906075/portfolio/avocado_sk7jwy.svg"
              alt="Avocado"
            />
          </div>
        </div>
      </div>

      <div id="about" className="full-width h-section white-bg">
        <div className="container max-w-screen-lg pt-16 pb-24 px-4">
          <h3 className="text-gray-900 text-3xl lg:text-4xl mt-0">
            About <span className="text-teal-500">Jonny</span>{" "}
          </h3>

          <img
            loading="lazy"
            data-src="https://res.cloudinary.com/dl8ynwaiu/image/upload/v1587906078/portfolio/jonny_acevedo_qr2imj.jpg"
            alt="Jonny Acevedo"
            className="lazyload md:float-right md:mt-2 md:ml-4 rounded-sm shadow-lg"
          />
          <p>
            I'm a <strong className="text-gray-800">front-end developer</strong>{" "}
            from Medellín, Colombia, I've been working building things for the
            web with amazing people for years. I like coding and developing webs
            & apps.
          </p>

          <p>
            I studied an associated on multimedia production and a
            specialization on hybrid mobile app development.
          </p>

          <p>
            On my free time, besides learning new technologies, I like planting
            avocados, spending time outdoors and enjoying time with friends and
            family.
          </p>

          <p>
            Here are a few technologies and platforms I've been working with:
          </p>
          <div className="md:flex">
            <div className="md:w-1/2">
              <strong className="text-black">Skills & Languages</strong>
              <div className="md:flex mt-4">
                <ul className="list-disc pl-4 mb-3 leading-relaxed">
                  <li>AEM (FE)</li>
                  <li>JavaScript</li>
                  <li>React</li>
                  <li>Angular & TypeScript</li>
                  <li>Vue</li>
                </ul>

                <ul className="md:ml-10 list-disc pl-4 mb-3 leading-relaxed">
                  <li>Node.js & Express.js</li>
                  <li>HTML & (S)CSS</li>
                  <li>Ionic</li>
                  <li>WordPress</li>
                </ul>
              </div>
            </div>
            <div className="md:w-1/2 mt-6 md:mt-0">
              <strong className="text-black">Learning & Interested</strong>
              <div className="md:flex mt-4">
                <ul className="list-disc pl-4 mb-3 leading-relaxed">
                  <li>Flutter & Dart</li>
                  <li>Golang</li>
                </ul>

                <ul className="md:ml-10 list-disc pl-4 mb-3 leading-relaxed">
                  <li>Serverless</li>
                  <li>Gatsby.js</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="tech-logos full-width h-section gray-bg">
        <div className="container max-w-screen-lg py-16 px-4">
          <div className="floating-section">
            <p className="text-2xl font-bold">Want to work together??</p>

            <a
              href="/assets/resume-jonny-acevedo-front-end-developer.pdf"
              className="home__btn btn btn--full block sm:inline-block mx-auto"
              rel="noopener noreferrer"
              target="_blank"
            >
              My Resume
            </a>
            <a
              href="#contact-me"
              className="home__btn btn block sm:inline-block mt-4 sm:mt-0 sm:ml-4 mx-auto"
            >
              Contact Me
            </a>
          </div>

          <div className="flex tech-logos__cont">
            <div>
              <img
                loading="lazy"
                data-src="https://res.cloudinary.com/dl8ynwaiu/image/upload/v1587906081/portfolio/logos/aem-logo_vof1sm.svg"
                alt="AEM Logo"
                className="lazyload"
              />
            </div>
            <div>
              <img
                loading="lazy"
                data-src="https://res.cloudinary.com/dl8ynwaiu/image/upload/v1587906080/portfolio/logos/js-logo_imzuqv.svg"
                alt="JS Logo"
                className="lazyload"
              />
            </div>
            <div>
              <img
                loading="lazy"
                data-src="https://res.cloudinary.com/dl8ynwaiu/image/upload/v1587906080/portfolio/logos/angular-logo_gzkcgf.svg"
                alt="Angular Logo"
                className="lazyload"
              />
            </div>
            <div>
              <img
                loading="lazy"
                data-src="https://res.cloudinary.com/dl8ynwaiu/image/upload/v1587906080/portfolio/logos/nodejs-logo_zvhylr.svg"
                alt="Node.js Logo"
                className="lazyload"
              />
            </div>
            <div>
              <img
                loading="lazy"
                data-src="https://res.cloudinary.com/dl8ynwaiu/image/upload/v1620093790/portfolio/logos/xrhxqkfo6vhsbyzlvjf3.svg"
                alt="Flutter Logo"
                className="lazyload"
              />
            </div>
            <div>
              <img
                loading="lazy"
                data-src="https://res.cloudinary.com/dl8ynwaiu/image/upload/v1587906081/portfolio/logos/react-logo_v9yrst.svg"
                alt="React Logo"
                className="lazyload"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="full-width h-section">
        <div className="container max-w-screen-lg py-16 px-4">
          <h3 className="text-gray-900 text-3xl lg:text-4xl mt-0 mb-12">
            Featured <span className="text-teal-500">Projects</span>{" "}
          </h3>
          <div className="bg-gray-200 py-10 px-10 lg:py-12 lg:px-20 rounded">
            <div className="flex flex-col md:flex-row">
              <div className="md:flex md:flex-col justify-center flex-1">
                <h4 className="text-gray-900 text-2xl lg:text-3xl">AEM</h4>
                <p>
                  Lead <strong>FrontEnd Developer</strong> for Kennametal
                  website developed on AEM content manager and integrated with
                  Hybris for e-commerce.
                </p>
                <a
                  href="https://www.kennametal.com/"
                  className="home__btn btn btn--full"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  Visit Website
                </a>
              </div>
              <div className="md:w-1/2 md:pl-6 order-first md:order-last">
                <img
                  data-src="https://res.cloudinary.com/dl8ynwaiu/image/upload/v1587906079/portfolio/kennametal_znzagg.jpg"
                  alt="Kennametal AEM Website"
                  className="lazyload mb-6 md:mb-0 shadow-xl"
                />
              </div>
            </div>
          </div>
          <hr className="hr--green" />
          <div className="md:flex">
            <div className="flex-1">
              <h4 className="text-gray-900 text-2xl lg:text-3xl mt-0 mb-12 mr-8 pb-2 border-b-2 border-teal-500">
                Digicel
              </h4>

              <div className="featured-list">
                <div className="flex">
                  <div className="featured-list__icon">
                    <img
                      src="https://res.cloudinary.com/dl8ynwaiu/image/upload/v1587906076/portfolio/info_nmufqz.svg"
                      alt="Code"
                    />
                  </div>
                  <div>
                    <h5>About</h5>
                    <p>
                      AEM front-end developer for an important Caribbean mobile
                      phone network website.
                    </p>
                  </div>
                </div>
                <div className="flex">
                  <div className="featured-list__icon">
                    <img
                      src="https://res.cloudinary.com/dl8ynwaiu/image/upload/v1587906082/portfolio/phone_hzlkvi.svg"
                      alt="Phone"
                    />
                  </div>
                  <div>
                    <h5>Front-end</h5>
                    <p>Vue.js 2, gulp, webpack</p>
                  </div>
                </div>
              </div>
              <a
                href="https://www.digicelgroup.com/jm/en.html"
                className="home__btn btn btn--full mb-12 md:mb-0 mt-6"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                Visit Website
              </a>
            </div>
            <div className="md:w-3/5 slider">
              <img
                className="lazyload shadow-xl"
                data-src="https://res.cloudinary.com/dl8ynwaiu/image/upload/v1607901130/portfolio/z6kz03ma0emnpwdg0swk.png"
                alt="Digicel AEM website"
              />
            </div>
          </div>
          {/* <hr className="hr--green" /> */}
          {/* <div className="md:flex">
            <div className="flex-1 md:order-last">
              <h4 className="text-gray-900 text-2xl lg:text-3xl mt-0 mb-12 md:ml-8 pb-2 border-b-2 border-teal-500">
                JobSi
              </h4>

              <div className="featured-list featured-list--right">
                <div className="flex">
                  <div className="featured-list__icon">
                    <img
                      src="https://res.cloudinary.com/dl8ynwaiu/image/upload/v1587906076/portfolio/info_nmufqz.svg"
                      alt="Code"
                    />
                  </div>
                  <div>
                    <h5>About</h5>
                    <p>
                      Job board app allowing users and companies to list their
                      profiles and jobs for free.
                    </p>
                  </div>
                </div>
                <div className="flex">
                  <div className="featured-list__icon">
                    <img
                      src="https://res.cloudinary.com/dl8ynwaiu/image/upload/v1587906082/portfolio/phone_hzlkvi.svg"
                      alt="Phone"
                    />
                  </div>
                  <div>
                    <h5>Front-end</h5>
                    <p>Ionic & Angular</p>
                  </div>
                </div>
                <div className="flex">
                  <div className="featured-list__icon">
                    <img
                      src="https://res.cloudinary.com/dl8ynwaiu/image/upload/v1587906075/portfolio/code_jzyptc.svg"
                      alt="Code"
                    />
                  </div>
                  <div>
                    <h5>Backend</h5>
                    <p>Firebase - Cloud Firestore</p>
                  </div>
                </div>
              </div>
              <a
                href="https://play.google.com/store/apps/details?id=co.applist.jobsi"
                className="home__btn btn btn--full mb-12 md:mb-0 mt-6 md:ml-8"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                Download on Play Store
              </a>
            </div>
            <div className="md:w-2/5 slider slider--jobsi">
              <Slider {...settings}>
                <div>
                  <img
                    loading="lazy"
                    data-src="https://res.cloudinary.com/dl8ynwaiu/image/upload/v1587906080/portfolio/jobsi/1_uxlgtb.jpg"
                    alt="JobSi, job board App"
                    className="lazyload"
                  />
                </div>
                <div>
                  <img
                    loading="lazy"
                    data-src="https://res.cloudinary.com/dl8ynwaiu/image/upload/v1587906078/portfolio/jobsi/2_v26jpl.jpg"
                    alt="JobSi, job board App"
                    className="lazyload"
                  />
                </div>
                <div>
                  <img
                    loading="lazy"
                    data-src="https://res.cloudinary.com/dl8ynwaiu/image/upload/v1587906081/portfolio/jobsi/3_szw7rx.jpg"
                    alt="JobSi, job board App"
                    className="lazyload"
                  />
                </div>
                <div>
                  <img
                    loading="lazy"
                    data-src="https://res.cloudinary.com/dl8ynwaiu/image/upload/v1587906077/portfolio/jobsi/4_hmqzd2.jpg"
                    alt="JobSi, job board App"
                    className="lazyload"
                  />
                </div>
              </Slider>
            </div>
          </div> */}
        </div>
        <div className="container max-w-screen-lg pt-6 md:pt-12 pb-12 px-4">
          <h3 className="text-gray-900 text-3xl lg:text-4xl mt-0 mb-12">
            Random <span className="text-teal-500">Posts</span>{" "}
          </h3>
          <div className="md:flex mb-6">
            <div className="flex items-center md:justify-center flex-1 mb-6 bg-gray-100 py-6 px-2 md:mr-6 text-center border-b-2 border-teal-500">
              <Link
                to="/blog/some-flutter-notes"
                className="text-xl text-gray-600 hover:text-gray-600"
              >
                Some Flutter Notes
              </Link>
            </div>
            <div className="flex items-center md:justify-center flex-1 mb-6 bg-gray-100 py-6 px-2 md:mr-6 text-center border-b-2 border-teal-500">
              <Link
                to="/blog/creating-a-rest-api-with-nest-js"
                className="text-xl text-gray-600 hover:text-gray-600"
              >
                Creating a REST API with NestJS
              </Link>
            </div>
            <div className="flex items-center md:justify-center flex-1 mb-6 bg-gray-100 py-6 px-2 text-center border-b-2 border-teal-500">
              <Link
                to="/blog/cies-the-fraternity-i-was-looking-for"
                className="text-xl text-gray-600 hover:text-gray-600"
              >
                CIES, the fraternity I was looking for
              </Link>
            </div>
          </div>
          <div className="text-center">
            <Link
              to="/blog"
              className="home__btn btn btn--full block md:mx-auto mb-6 mt-12 md:mt-6"
            >
              Read Blog
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default HomePage
